@import '../../index.css';

.contact {
	width: 100%;
	min-height: 100vh;
	height: auto;
	background-color: var(--dark);
	color: var(--light);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.form-wrapper {
	width: 500px;
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

input,
textarea {
	text-indent: 5px;
	background-color: transparent;
	outline: none;
	border: 0;
	border-bottom: 1px solid var(--light);
	width: 100%;
	font-size: 1.25rem;
	color: var(--light);
}

.send-btn {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin: 2rem;
	width: 15rem;
	height: 3.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.25rem;
	background-color: transparent;
	color: var(--secondary);
	border: 1px solid var(--secondary);
	border-radius: 10px;
	transition: 150ms all ease-in;
}

.send-btn:hover {
	background-color: var(--secondary);
	color: var(--dark);
}

.send-btn:hover #msg-btn {
	transform: translateX(20px);
}

.success-alert {
	color: var(--dark);
	position: fixed;
	top: -100px;
	height: 3rem;
	width: 16rem;
	background-color: var(--light);
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 5px solid rgb(16, 191, 0);
	border-radius: 3px;
	transition: 300ms all ease;
	box-shadow: 1px 2px 5px var(--dark);
}

.alert-container {
	z-index: 10;
	position: fixed;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.error-alert {
	z-index: 500;
	color: var(--dark);
	position: fixed;
	top: -100px;
	height: 5rem;
	width: 16rem;
	background-color: var(--light);
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 5px solid red;
	border-radius: 3px;
	transition: 300ms all ease;
	box-shadow: 1px 2px 5px var(--dark);
}

#msg-btn {
	font-size: 2.5rem;
	transform: translateX(6px);
	transition: 200ms ease;
}

textarea {
	height: 100px;
}

textarea:focus {
	box-shadow: 0px 1px 0px var(--light);
}

input:focus {
	box-shadow: 0px 1px 0px var(--light);
}
.contact-msg {
	font-size: clamp(1rem, 1.3vw, 1.25rem);
	width: 500px;
}

.contactHeader {
	font-size: clamp(5rem, 7vw, 7rem);
	margin: 2rem;
}

.form-wrapper > input {
	margin: 15px 0px;
}

@media screen and (max-width: 750px) {
	.form-wrapper {
		width: 68vw;
	}
	.contact-msg {
		width: 68vw;
	}
	.contactHeader {
		font-size: 4rem;
	}
}
@media screen and (max-width: 375px) {
	.contactHeader {
		font-size: 3rem;
	}
}
