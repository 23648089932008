@import '../../index.css';

.hamburger-container {
	position: -webkit-sticky;
	position: sticky;
	right: 30px;
	top: 30px;
	width: 3.5rem;
	height: 3.5rem;
	background-color: var(--dark);
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	z-index: 3;
}

.drop-nav {
	top: -1500px;
	position: fixed;
	margin: 0 auto;
	width: 100%;
	height: 100vh;
	color: var(--light);
	background-color: var(--dark);
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 6rem;
	transition: 500ms ease;
	opacity: 0;
}

.nav-li:hover {
	opacity: 100%;
	transform: scale(120%);
}

.hamburger {
	margin-top: 4px;
	margin-bottom: 4px;
	width: 30px;
	height: 2px;
	background-color: var(--light);
	transition: 150ms all ease-in;
}

@media (hover: hover) {
	.hamburger-container:hover .hamburger:nth-of-type(2) {
		transform: translateX(-4px);
		background-color: var(--secondary);
	}
}

.nav-container {
	display: flex;
	justify-content: flex-end;
	height: var(--navHeight);
	width: 100px;
	position: absolute;
	right: 0;
}

.nav-li {
	opacity: 50%;
	list-style: none;
	cursor: pointer;
	transition: 300ms ease-in-out;
}

@media screen and (max-width: 750px) {
	.hamburger-container {
		width: 2.5rem;
		height: 2.5rem;
		right: 15px;
		top: 15px;
	}

	.hamburger {
		width: 25px;
	}
	.drop-nav {
		font-size: 4rem;
	}
}
