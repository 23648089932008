@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Viga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');

:root {
	--primary: #041b5d;
	--secondary: #55fff9;
	--light: #fffdfb;
	--dark: #212026;
	--projectTabHeight: 50px;
	--topicTabHeight: 82px;
	--navHeight: 110vh;
}

* {
	font-family: 'Koho', sans-serif;
	scroll-behavior: smooth;
}

body {
	background-color: var(--light);
}

.app-container {
	display: flex;
	flex-direction: column;
}
