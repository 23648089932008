@import '../../index.css';

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateX(-40px);
	}
	to {
		opacity: 1;
		transform: translateX(0px);
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateX(-40px);
	}
	to {
		opacity: 1;
		transform: translateX(0px);
	}
}

.link {
	color: var(--secondary);
	text-decoration: underline;
	text-decoration-color: var(--secondary);
    cursor: pointer;
}

.link:hover {
    color: var(--secondary);
    text-decoration: none;
}


.about {
	width: 100%;
	height: auto;
	min-height: 90vh;
	background-color: var(--dark);
	color: var(--light);
	display: flex;
	justify-content: center;
	align-items: center;
}

.h2-about {
	font-size: 3rem;
	position: relative;
	transform: translate(-25rem, -2.5rem);
}

.content-wrapper {
	flex-grow: 1;
	display: flex;
}

.about-container {
	/* transform: translateX(-120px); */
	display: flex;
	flex-direction: column;
	width: 80%;
	height: 30rem;
	align-items: center;
	margin: 12rem 0;
}

.topic-container {
	display: flex;
	height: 100%;
	flex-grow: 1;
	border-left: 2px solid var(--light);
	flex-direction: column;
}

.topic-tab {
	flex-grow: 1;
	background-color: transparent;
	outline: none;
	border: none;
	padding: 0 4rem;
	white-space: nowrap;
	font-size: 1.5rem;
	color: var(--light);
	transition: 250ms ease;
}

.topic-highlight {
	position: absolute;
	transform: translate(-3.5px, -2px);
	height: var(--topicTabHeight);
	width: 5px;
	border-radius: 10px;
	background-color: var(--secondary);
	transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.topic-tab:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.selected-topic {
	background-color: rgba(255, 255, 255, 0.2);
	color: var(--secondary);
	transition: 250ms ease;
}

.react-icon {
	color: #61dafb;
}

.js-icon {
	color: #ead41c;
}

.laravel-icon {
	color: #EF3E30;
}

.bootstrap-icon {
	color: #6a409f;
}

.vue-icon {
	color: #41b883;
}

.firebase-icon {
	color: #f2c129;
}

.topic-panel {
	height: 100%;
	width: 60rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 4rem;
	font-size: 1.15rem;
}

.topic-text {
	display: none;
}

.tech-icons {
	margin-top: 2rem;
	display: flex;
	width: 100%;
	justify-content: space-evenly;
}

.show-topic {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	-webkit-animation: fadeIn 1s;
	animation: fadeIn 1s;
}

@media screen and (max-width: 1300px) {
	.about-container {
		width: 70%;
	}

	.h2-about {
		transform: translate(0, -2.5rem);
	}

	.content-wrapper {
		width: 100%;
	}
	.topic-panel {
		padding: 0.5rem 1rem 0.5rem 3rem;
		font-size: 1.1rem;
	}
	.topic-tab {
		padding: 0 3rem;
	}
}
/* @media screen and (max-width: 1050px) {
	.about-container {
		width: 60%;
	}
} */
@media screen and (max-width: 865px) {
	.topic-tab {
		padding: 0 2rem;
	}
	.topic-panel {
		padding: 0.5rem 1rem 0.5rem 2rem;
		font-size: 1rem;
	}
	.about-container {
		width: 80vw;
		align-items: center;
		margin: 8rem 0;
	}

	svg {
		height: 30px;
	}
	.h2-about {
		transform: translate(0);
		margin-bottom: 2rem;
	}
}
@media screen and (max-width: 630px) {
	.about-container {
		height: auto;
		margin: 5rem 0;
	}
	.topic-panel {
		font-size: 1rem;
		padding: 0.5rem;
		width: 90%;
		text-align: center;
	}
	.topic-highlight {
		display: none;
	}
	.tech-icons {
		margin-top: 1rem;
	}

	.topic-tab {
		font-size: 1.2rem;
		padding: 0;
	}
	.topic-tab:focus {
		box-shadow: 0 2px 0 var(--secondary);
	}
	.topic-container {
		flex-direction: row;
		border-left: none;
		border-bottom: 1px solid var(--light);
		width: 85%;
		height: 4rem;
		margin-bottom: 1rem;
	}
	.content-wrapper {
		align-items: center;
		flex-direction: column;
	}
}
@media screen and (max-width: 430px) {
	.topic-tab {
		font-size: 1.1rem;
		padding: 0;
	}
}
