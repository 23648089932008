@import '../../index.css';

.projects {
	width: 100%;
	height: auto;
	min-height: 90vh;
	background-color: var(--light);
	color: var(--dark);
	display: flex;
	justify-content: center;
	align-items: center;
}

.braille-link {
	color: var(--primary);
	text-decoration: underline;
	text-decoration-color: var(--primary);
}

.github-icon {
	color: var(--dark);
	transition: 250ms ease;
}

.github-icon:hover {
	color: rgb(80, 80, 80);
	transform: translateY(-5px);
}

.ext-icon {
	color: var(--dark);
	transition: 250ms ease;
}

.ext-icon:hover {
	color: rgb(80, 80, 80);
	transform: translateY(-5px);
}

@-webkit-keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translateX(40px);
	}
	to {
		opacity: 1;
		transform: translateX(0px);
	}
}
@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translateX(40px);
	}
	to {
		opacity: 1;
		transform: translateX(0px);
	}
}

.h2-projects {
	padding-right: 2rem;
	margin-bottom: 4rem;
	transform: translate(20rem, 0);
	font-size: 3rem;
}

.content-wrapper-proj {
	flex-grow: 1;
	display: flex;
}

.projects-container {
	display: flex;
	flex-direction: column;
	width: 80%;
	align-items: center;
	margin: 12rem 0;
}

.project-container {
	position: relative;
	display: flex;
	height: auto;
	flex-grow: 1;
	border-right: 2px solid var(--dark);
	flex-direction: column;
}

.project-tab {
	text-align: right;
	flex-grow: 1;
	background-color: transparent;
	outline: none;
	border: none;
	padding: 0 2rem;
	white-space: nowrap;
	font-size: 1.5rem;
	color: var(--dark);
	transition: 250ms ease;
}

.project-highlight {
	position: absolute;
	right: 0;
	transform: translate(3.5px, 0px);
	height: var(--projectTabHeight);
	width: 5px;
	border-radius: 10px;
	background-color: var(--primary);
	transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.project-tab:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.project-description {
	margin: 1.5rem 0rem;
}

.icon-container {
	margin-top: 1.5rem 0;
	justify-content: flex-end;
}

.icon-container * {
	margin: 0 1rem;
}

.tech-container {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 1.5rem;
}

.tech-li {
	cursor: default;
	text-align: center;
	margin: 0.25rem 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.9rem;
	width: auto;
	height: auto;
	border-radius: 20px;
	list-style: none;
	padding: 0.2rem 1.2rem;
	border: 1px solid var(--dark);
	background-color: var(--primary);
	color: var(--light);
}

.selected-project {
	background-color: rgba(0, 0, 0, 0.2);
	color: var(--primary);
	transition: 250ms ease;
}

.project-panel {
	text-align: center;
	height: 100%;
	width: 60rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem 4rem;
	font-size: 1.15rem;
}

.project-text {
	display: none;
}

.show-project {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	-webkit-animation: fadeInRight 1s;
	animation: fadeInRight 1s;
}

@media screen and (max-width: 1400px) {
	.projects-container {
		width: 80%;
	}

	.h2-projects {
		margin-bottom: 3rem;
		transform: translate(0, 0);
		padding-right: 0;
	}

	.content-wrapper-proj {
		width: 100%;
	}
	project-panel {
		padding: 0.5rem 3rem 0.5rem 1rem;
	}
	.project-tab {
		padding: 0 1rem;
		font-size: 1.25rem;
	}
}

@media screen and (max-width: 1050px) {
	.project-panel {
		padding: 0.5rem 2rem 0.5rem 1rem;
	}
	.project-tab {
		padding: 0 1rem;
		font-size: 1.1rem;
	}
	.projects-container {
		width: 73vw;
		align-items: center;
		margin: 8rem 0;
	}

	.h2-project {
		transform: translate(0);
		margin-bottom: 2rem;
	}
	.tech-li {
		margin: 0.2rem 0.4rem;
		font-size: 0.8rem;
		padding: 0.1rem 1rem;
	}
	.icon-container * {
		margin: 0 0.7rem;
	}
}

@media screen and (max-width: 630px) {
	.projects-container {
		height: auto;
	}
	.icon-container * {
		margin: 0 0.3rem;
	}

	.h2-projects {
		height: auto;
		font-size: 2.5rem;
		margin-bottom: 1.5rem;
		width: 70vw;
		text-align: center;
	}
	.project-panel {
		font-size: 1rem;
		padding: 0.5rem;
		width: 90%;
		text-align: center;
	}
	.project-highlight {
		display: none;
	}
	.tech-icons {
		margin-top: 1rem;
	}
	.tech-li {
		font-size: 0.7rem;
	}

	.project-tab {
		text-wrap: wrap;
		width: 7rem;
		font-size: 0.9rem;
		padding: 0.25rem;
		text-align: center;
	}
	.project-tab:focus {
		box-shadow: 0 2px 0 var(--primary);
	}
	.project-container {
		flex-direction: row;
		flex-wrap: wrap;
		border-right: none;
		border-bottom: 1px solid var(--dark);
		width: 90%;
		height: auto;
		margin-bottom: 1rem;
	}
	.content-wrapper-proj {
		align-items: center;
		flex-direction: column-reverse;
	}
}
