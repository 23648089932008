@import '../../index.css';

.footer-wrapper {
	width: 100%;
	height: 10vh;
	background-color: var(--light);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 400ms ease;
}

@media (hover: hover) {
	.footer-wrapper:hover {
		transform: translateY(-20px);
	}

	.return-btn:hover #home-tab {
		color: var(--secondary);
		transform: scale(150%);
	}

	.return-btn:hover {
		transform: translateY(-10px);
	}
}

.return-btn {
	border: none;
	position: relative;
	top: -10px;
	width: 300px;
	height: 100px;
	border-radius: 30%;
	background-color: var(--light);
	display: flex;
	justify-content: center;
	transition: 250ms ease;
	cursor: pointer;
}

#home-tab {
	font-size: 3rem;
	transition: 250ms ease;
}

.footer-credits {
	font-size: 1.25rem;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 2.5vw;
}

.bottom-nav-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 2.5vw;
}

.bottom-nav-container div {
	padding: 0 1vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bot-nav-link {
	height: 100%;
	font-size: 1.25rem;
}

.bot-nav-link:hover {
	cursor: pointer;
	text-decoration: underline;
	color: var(--primary);
}

@media screen and (max-width: 1200px) {
	.footer-credits,
	.bot-nav-link {
		font-size: 1rem;
	}
}
@media screen and (max-width: 750px) {
	#home-tab {
		font-size: 2rem;
	}

	.return-btn {
		width: 250px;
		top: -30px;
	}

	.bottom-nav-container {
		flex-direction: column;
		margin: 1rem 0;
	}
	.footer-wrapper {
		height: auto;
	}
}
