@import '../../index.css';

.profile-pic {
	height: 100vh;
}

.home {
	display: flex;
}

.intro-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	min-height: 100vh;
	width: 100%;
	padding-left: 20px;
}
.intro {
	height: 50%;
	width: 80%;
}

.h4-intro {
	font-weight: 400;
	font-size: 3rem;
}

.h4-intro span {
	transform: translateX(10px);
	font-weight: 700;
	font-size: 4rem;
	color: var(--primary);
}

.h2-intro + .h4-intro {
	transform: translateY(-25px) translateX(40px);
}

.h2-intro {
	transform: translateX(-50px);
	font-size: 8rem;
	color: var(--primary);
	font-family: 'Viga';
}

.p-intro {
	padding-top: 0;
	font-size: 1.5rem;
}

.btn-container {
	height: 5rem;
	width: 35vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.about-btn {
	color: var(--primary);
	width: 12rem;
	height: 3rem;
	border: 2px solid var(--primary);
	background-color: transparent;
	border-radius: 0.5rem;
	transition: 150ms all ease-in;
	font-size: 1.25rem;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.about-btn:hover {
	background-color: var(--primary);
	color: var(--light);
}

@media screen and (max-width: 1650px) {
	.h2-intro {
		font-size: 5rem;
	}

	.h4-intro {
		font-size: 2rem;
	}

	.h4-intro span {
		font-size: 3rem;
	}

	.p-intro {
		font-size: 1.25rem;
	}
}

@media screen and (max-width: 1275px) {
	.profile-pic {
		display: none;
	}

	.intro-wrapper {
		padding: 0;
	}

	.intro {
		height: 50%;
		width: 72%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
	}
	.h2-intro + .h4-intro {
		transform: translateY(0) translateX(0);
		margin-bottom: 1rem;
	}

	.h4-intro span {
		transform: translateX(0);
	}
	.h2-intro {
		transform: translateX(0);
		font-size: 8rem;
	}
	.p-intro {
		text-align: center;
	}
}

@media screen and (max-width: 1050px) {
	.h2-intro {
		font-size: 5rem;
	}
	.h4-intro {
		font-size: 1.75rem;
	}
}

@media screen and (max-width: 660px) {
	.h2-intro {
		font-size: 3rem;
	}
	.h4-intro {
		font-size: 1.5rem;
	}
	.p-intro {
		font-size: 1rem;
	}

	.h4-intro span {
		font-size: 2rem;
	}
}

@media screen and (max-width: 300px) {
	* {
		font-size: x-small;
	}
}
